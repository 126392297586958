<template>
  <div>RTZ Viewer</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.commitRoot("set_ui", this.$route.fullPath);
  },
};
</script>
